
















































































































































    import { Component, Vue } from 'vue-property-decorator';
    import AppContainerBase from '@/components/util/AppContainerBase.vue';
    import { IDataTable } from '@/model/main/IDataTable';
	import { IHeaderTable } from '@/model/main/IHeaderTable';

    @Component({
        name: 'AdminGerente',
        components: {
            AppContainerBase
        },
    })

    export default class AdminGerente extends Vue {
        public title = "Vista de Administración (Gerente)";
        public btnAgregarUsuario = "Agregar usuario";
        public btnDialogUsuarioAceptar = "Solicitar";
        public btnDialogUsuarioRechazar = "Atrás";
        
        public isLoading = false;
        public dialog_users = false;
        public script_resumen = false;

        public header_users:Array<IHeaderTable<IDataTable>> = [
            {text: 'Nombre', value: 'username'},
            {text: 'Nivel', value: 'level'},
            {text: 'Correo', value: 'email'},
            {text: 'Reportes', value: 'reports'},
            {text: 'Estado', value: 'state'}
        ];
        public rows_users:Array<IDataTable> = [];
        public form_user = {
            nombre: '',
            nivel: '',
            correo: '',
            reportes: []
        }

        public header_mods:Array<IHeaderTable<IDataTable>> = [
            {text: 'Campaña', value: 'campania'},
            {text: 'Categoria', value: 'categoria'},
            {text: 'Subcategoria', value: 'subcategoria'},
            {text: 'Usuario', value: 'username'},
            {text: 'Acción', value: 'accion'},
            {text: 'Elementos', value: 'elementos'},
            {text: '', value: ''},
            {text: '', value: ''},
            {text: '', value: ''},
        ];
        public rows_mods:Array<IDataTable> = []

        public script_nombre = ""
        public scripts:any = {}
        public header_resumen:Array<IHeaderTable<IDataTable>> = [
            {text: 'Categoria', value: 'categoria'},
            {text: 'Subcategoria', value: 'subcategoria'},
            {text: 'Elementos', value: 'elementos'},
            {text: '', value: 'flecha'},
            {text: 'Nuevos elementos', value: 'elementos_new'},
        ]
        public rows_resumen:Array<IDataTable> = []

        /** METODOS */
        public solicitarUsuario() {
            let nuevo_user = {
                username: this.form_user.nombre,
                level: this.form_user.nivel,
                email: this.form_user.correo,
                reports: this.form_user.reportes,
                state: 'Solicitado'
            }
            this.rows_users.push(nuevo_user);
            this.form_user = {
                nombre: '',
                nivel: '',
                correo: '',
                reportes: []
            }
            this.dialog_users = false;
        }

        public sumarListas(lista1:Array<string>, lista2:Array<string>) {
            const set = new Set([...lista1, ...lista2]);
            return Array.from(set);
        }

        public restarListas(lista1:Array<string>, lista2:Array<string>) {
            const set2 = new Set(lista2);
            return lista1.filter(item => !set2.has(item));
        }

        public detalleMod(item:IDataTable) {
            // console.log(item);
            this.script_nombre = item.campania;
            let rows = []
            for (let sub of this.scripts[this.script_nombre]) {
                // console.log(sub);
                if (sub.subcategoria == item.subcategoria) {
                    const nuevo_item = JSON.parse(JSON.stringify(sub));
                    nuevo_item['flecha'] = '→'
                    if (item.accion == 'Agregar') {
                        nuevo_item['elementos_new'] = this.sumarListas(sub.elementos, item.elementos)
                    } else {
                        nuevo_item['elementos_new'] = this.restarListas(sub.elementos, item.elementos)
                    }
                    rows.push(nuevo_item);
                } else {
                    rows.push(sub)
                }
            }
            this.rows_resumen = rows;
            this.script_resumen = true;
        }

        public aceptarMod(item:IDataTable) {
            console.log(item);
            for (let sub of this.scripts[item.campania]) {
                if (sub.subcategoria == item.subcategoria) {
                    if (item.accion == 'Agregar') {
                        sub['elementos'] = this.sumarListas(sub.elementos, item.elementos)
                    } else {
                        sub['elementos'] = this.restarListas(sub.elementos, item.elementos)
                    }
                    break
                }
            }
            this.rows_mods = this.rows_mods.filter(mod => mod.id !== item.id);
            console.log(this.rows_mods);
        }

        public rechazarMod(item:IDataTable) {
            console.log(item);
            this.rows_mods = this.rows_mods.filter(mod => mod.id !== item.id);
            console.log(this.rows_mods);
        }

        public obtenerUsers() {
            let users = [
                {username: 'Alejandro Dumas', level: 'Administrador', email: 'alejandro.dumas@alloxentric.com', reports: ['Mora', 'Evaluación', 'Carga'], state: 'Creado'},
                {username: 'Max', level: 'Administrador', email: 'max@alloxentric.com', reports: ['Mora', 'Evaluación', 'Carga'], state: 'Creado'},
                {username: 'Tamara Diaz', level: 'Supervisor', email: 'tamara.diaz@gmail.com', reports: ['Evaluación', 'Mora'], state: 'Solicitado'},
                {username: 'Juan Perez', level: 'Agente', email: 'juan.perez@gmail.com', reports: ['Evaluación individual'], state: 'Solicitado'},
            ]
            this.rows_users = users;
        }

        public obtenerMods() {
            let mods = [
                {id: '1', campania: 'Demo_20240416', categoria: 'SALUDO', subcategoria: 'Identificacion ejecutivo', username: 'Alejandro Dumas', accion: 'Agregar', elementos: ['mi nombre es']},
                {id: '2', campania: 'Demo_20240416', categoria: 'DESPEDIDA', subcategoria: 'Despedida cordial', username: 'Max', accion: 'Agregar', elementos: ['hasta luego']},
                {id: '3', campania: 'Demo_20240416', categoria: 'NEGOCIACION', subcategoria: 'Informa cuota pago', username: 'Alejandro Dumas', accion: 'Eliminar', elementos: ['la cuota']},
                {id: '4', campania: 'Demo_20240416', categoria: 'NEGOCIACION', subcategoria: 'Consulta motivo de mora', username: 'Alejandro Dumas', accion: 'Eliminar', elementos: ['porque', 'por que']},
            ]
            this.rows_mods = mods;
        }

        public obtenerScripts() {
            let scripts = {
                'Demo_20240416': [
                    {categoria: 'SALUDO', subcategoria: 'Saludo inicial', elementos: ['hola', 'buenos dias']},
                    {categoria: 'SALUDO', subcategoria: 'Identificacion ejecutivo', elementos: ['yo soy', 'me llamo']},
                    {categoria: 'NEGOCIACION', subcategoria: 'Informa cuota pago', elementos: ['la cuota', 'debe pagar', 'la deuda']},
                    {categoria: 'NEGOCIACION', subcategoria: 'Consulta motivo de mora', elementos: ['el motivo', 'porque', 'por que', 'la razon']},
                    {categoria: 'DESPEDIDA', subcategoria: 'Despedida cordial', elementos: ['que este bien', 'nos vemos']}
                ]
            }
            this.scripts = scripts;
        }

        mounted() {
            this.isLoading = true;
            this.obtenerUsers();
            this.obtenerScripts();
            this.obtenerMods();
            this.isLoading = false;
        }
    }
